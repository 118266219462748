.App {
  text-align: center;
}

.App-header {
  background-color: white;
  min-height: 70vh;
  display: flex;
  align-content: center;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-body {
  position: relative;
  min-height: 70vh;
  padding-bottom: 35px;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  background-color: lightgrey;
  color: black;
}

.App-portfolio {
  position: relative;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  background-color: white;
}

@media screen and (min-width: 375px) {
  .App-body {
    min-height: 45vh;
  }
  .App-header {
    min-height: 35vh;
  }
  .App-portfolio {
    min-height: 30vh;
  }
}

@media screen and (max-width: 600px) {
  .Avatar {
    display: none;
  }
}

.img {
  margin-left: 15px;
  margin-right: 15px;
}

.img:hover {
  transform: scale(1.5);
}

.example {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  width: 65%;
  margin-right: auto;
  margin-left: auto;
  padding-top: 20px;
  padding-bottom: 20px;
}

@media screen and (min-width: 992px) {
  .example {
    flex-direction: row;
  }
}

.leaderboard {
  display: flex;
  border: 2px solid lightgrey;
  border-radius: 10px;
  margin-right: 20px;
  margin-top: 20px;
}

.portfolio {
  border-radius: 10px;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 20px;
}

.portfolio:hover {
  transform: scale(1.1);
}

.Avatar {
  width: 500px;
  height: 500px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
}

.SocialMedia {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 10%;
  margin-left: 10%;
}

.SocialMedia:hover {
  transform: scale(1.2);
}

.App-link {
  color: #61dafb;
}
